@font-face {
  font-family: "Inter";
  src:
    local("Inter"),
    url("./assets/Fonts/Inter-Regular.ttf") format("truetype");
}

:root {
  max-width: 100vw;
  max-height: 100vh;
  overflow: hidden !important;
  --primary-color: #01657f;
  --secondary-color: #7ca392;
  --error-color: #e84141;
  --success-color: #408140;
  --success-color-rgb: 40, 167, 69;
  --primary-color-rgba: 1, 101, 127;
  --secondary-color-rgba: 124, 163, 146;
  --error-color-rgba: 232, 65, 65;
  --success-color-rgba: 64, 129, 64;
}

.overFlowAuto {
  overflow: auto !important;
}

.primary-row {
  background-color: rgba(var(--success-color-rgb), 0.1);
}

.MuiAutocomplete-option[aria-selected="true"] {
  background-color: rgba(var(--success-color-rgb), 0.1) !important;
  font-weight: 600 !important;
  color: var(--primary-color);
}

.hover {
  cursor: pointer;
}

html,
body,
#app {
  font-size: 14px;
  margin: 0;
}

.back-red {
  background: #ffcbcb !important;
  background-color: #ffcbcb !important;
}

html {
  width: 100vw;
  scroll-behavior: smooth;
}

input {
  min-width: 0;
}

::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  border-radius: 10px;
  transition: all 0.4s ease;
}

::-webkit-scrollbar-track {
  background: #b5b5b56c;
  border-radius: 10px;
  transition: all 0.4s ease;
}

::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 10px;
  transition: all 0.4s ease;
}

::-webkit-scrollbar-thumb:hover {
  background: rgba(var(--primary-color-rgba), 0.3);
  transition: all 0.4s ease;
  cursor: pointer;
  border-radius: 0px;
}

.hidden::-webkit-scrollbar {
  width: 1px;
  border-radius: 0px;
  height: 1px;
}

.hidden::-webkit-scrollbar-track {
  background: rgba(var(--primary-color), 0.2);
  border-radius: 0px;
}

.hidden::-webkit-scrollbar-thumb {
  background: var(--primary-color);
  border-radius: 0px;
  transition: all 0.4s ease;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.highcharts-button-box {
  stroke: var(--primary-color) !important;
}

.highcharts-button-symbol {
  stroke: var(--primary-color) !important;
}

.highcharts-menu {
  background-color: #ecf2ff !important;
  border: 1px solid var(--primary-color) !important;
  border-radius: 0.5rem !important;
  box-shadow: 0 0 0.5rem var(--primary-color) !important;
  color: var(--primary-color) !important;
  padding: 0.2rem !important;
}

.highcharts-menu-item {
  font-size: 0.9rem !important;
  color: var(--primary-color) !important;
  font-weight: 700 !important;
  transition: all 0.2s ease-in-out;
}

.highcharts-menu-item:hover {
  background-color: var(--primary-color) !important;
  color: #ecf2ff !important;
}

.highcharts-range-selector-buttons > .highcharts-label {
  display: none;
}

.texture p {
  width: 100px;
  height: 190px;
  background-color: var(--primary-color);
  position: absolute;
}

.one {
  top: 0;
  left: 0;
  box-shadow:
    100px -60px 0 0 var(--primary-color),
    200px -120px 0 0 var(--primary-color);
}

.two {
  bottom: 0;
  right: 0;
  box-shadow:
    -100px 50px 0 0 var(--primary-color),
    -200px 100px 0 0 var(--primary-color),
    -300px 150px 0 0 var(--primary-color),
    -400px 175px 0 0 var(--primary-color);
}

.textUnderlineLogin::before {
  content: "";
  position: absolute;
  width: 200px;
  height: 8px;
  background-color: var(--secondary-color);
  top: 33px;
  transform: skew(-40deg);
  rotate: -3deg;
  z-index: 1;
}

.deactivate-header {
  color: #000;
  border-radius: 0.375rem;
}

.filter-item {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  border-radius: 4px;
  padding: 4px;
  background-color: #e3e8ef;
  color: #000;
}

.filter-item p {
  margin: 0;
  padding-right: 5px;
}

html.is-changing .transition-fade {
  transition: opacity 0.25s;
  opacity: 1;
}

html.is-animating .transition-fade {
  opacity: 0;
}

.small-case-row-background {
  background-color: rgb(255, 195, 195);
}

progress {
  border-radius: 4px;
  width: 100%;
  height: 16px;
}

.ck .ck-powered-by {
  display: none;
}

.ck {
  font-family: Inter;
}

.MuiDataGrid-row {
  animation: fadeIn 0.3s ease-in;
}

@keyframes fadeIn {
  from {
    opacity: 0;
    transform: translateY(20px);
  }

  to {
    opacity: 1;
    transform: translateY(0);
  }
}
